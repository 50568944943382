@import '../../../../libs/wallex-core/src/styles/variables';
@import '../../../../libs/wallex-core/src/styles/mixins';

:root {
  --base-one: #fff;

  //backgrounds and borders
  --body-background: #fff;
  --item-border: #e2e3e7;
  --plate-border: #e2e3e7;
  --item-background: #fff;
  --item-disabled-background: #f6f6f6;
  --item-secondary-background: #efefef;
  --dropdown-search-background: #f8f9fa;
  --section-border: #e2e3e7;
  --main-text: #111314;
  --secondary-text: #64686f;
  --border-secondary: #f3f3f3;
  --scrollbar-color: rgb(17 19 20 / 40%);
  --separator-color: #979797;
  --widget-border: #00c08b;

  //intercom color
  --intercom-color: #00c08b;

  //menu text colors
  --menu-item: rgb(255 255 255 / 70%);
  --menu-item-hover: #fff;
  --menu-icon: brightness(0) saturate(100%) invert(71%) sepia(68%) saturate(5582%) hue-rotate(129deg) brightness(98%) contrast(101%);
  --menu-icon-hover: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5864%) hue-rotate(332deg) brightness(120%) contrast(101%);

  //icons
  --icon-background: #00c08b;
  --icon-color: var(--menu-icon-hover);
  --grey-text-icons: #767676;
  --icon-danger-background: #f9e6e6;
  --icon-danger-color: brightness(0) saturate(100%) invert(19%) sepia(95%) saturate(2326%) hue-rotate(346deg) brightness(97%) contrast(85%);
  --icon-inactive: #a7a7a7;
  --hover-filter: invert(70%) sepia(45%) saturate(6962%) hue-rotate(127deg) brightness(96%) contrast(102%);

  //wallet types
  --fiat-type: #0084a1;
  --crypto-type: #00c08b;
  --saving-type: #47e2a1;
  --market-chart: #1e55b2;

  //cards
  --card-text: #fff;
  --card-text-title: rgb(#{hex-to-rgb(#fff)} / 60%);
  --card-cvv: #111314;
  --logo-width: 11.9em; // only in em
  --fields-card: unset;
  --card-logo: brightness(0) saturate(100%) invert(100%) sepia(94%) saturate(0%) hue-rotate(184deg) brightness(106%) contrast(107%);
  --card-background: var(--primary-gradient);

  // app colors
  --hint-background: #edf3fc;
  --notification-background: #eef4ff;
  --gradient-color1: #06306a;
  --gradient-color2: #31a9a8;
  --main-red: #cc3030;
  --grey: #efefef;
  --grey-background: #f6f6f6;
  --grey-easy: #d9d9d9;
  --grey-primary: #888989;
  --light-red: #f9e6e6;
  --light-orange: #fbefe1;
  --dark-green: #205e3b;
  --text-green: #2aac66;
  --light-green: #e5f5ed;
  --light-blue: #1e55b2;
  --orange: #e17c05;
  --hover-primary: #008d67;
  --extra-light: #f7faff;
  --primary-text: #00c08b;
  --button-primary: #00c08b;
  --other-blue: #1e55b2;

  // gradients
  --primary-gradient: linear-gradient(136.32deg, var(--gradient-color1) 0%, var(--gradient-color2) 97.8%);

  // filters
  --to-light-blue: brightness(0) saturate(100%) invert(23%) sepia(81%) saturate(1452%) hue-rotate(202deg) brightness(100%) contrast(99%);
  --image-hover-filter: brightness(0) saturate(100%) invert(71%) sepia(68%) saturate(5582%) hue-rotate(129deg) brightness(98%) contrast(101%);
  --to-black: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);

  //sizes
  --auth-logo-height: 24px;
  --dashboard-logo-width: 142px;
  --dashboard-logo-mobile-width: 28.5px;

  //fonts
  --font-base-light: poppins-light;
  --font-base-regular: poppins-regular;
  --font-base-medium: poppins-medium;
  --font-base-bold: poppins-bold;
  --font-base-semiBold: poppins-semibold;
  --font-sans-regular: opensans-regular;
  --font-sans-semiBold: opensans-semibold;
  --font-sans-medium: opensans-medium;
  --font-kanit-light: kanit-light;
  --font-kanit-bold: kanit-bold;
}

@media (max-width: $table-sm-breakpoint) {
  :root {
    --menu-item-hover: #111314;
    --menu-item: #00c08b;
    --menu-icon: brightness(0) saturate(100%) invert(48%) sepia(83%) saturate(991%) hue-rotate(126deg) brightness(94%) contrast(103%);
    --menu-icon-hover: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);
  }
}
